import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import ContactContent from '../components/contact/content'
import SEO from '../components/seo'

const Contact = ({ data, pageContext }) => (
  <Layout>
    <SEO pageTitle={pageContext.title} slug={pageContext.slug} {...data.wordPress.pageBy.seo} />
    <ContactContent content={data.wordPress.pageBy.contactFields.contactInfos} />
  </Layout>
)

export default Contact

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      pageBy(pageId: $wordpressId) {
        ...SeoFragment
        contactFields {
          ...ContactContentFragment
        }
      }
    }
  }
`
